<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-10-desktop is-8-widescreen">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>EdShed Hub</h1>
            </div>
          </div>
          <div class="box-body">
            <!-- login form -->
            <p>The EdShed hub has been merged with the main EdShed user area.</p>

            <div class="is-flex has-text-centered menuButtons">
              <!-- <a :href="loginUrl" class="button is-link"><span class="icon"><i class="fas fa-sign-in-alt" /></span> <span>Sign In</span></a> -->
              <a :href="edshedUrl" class="button is-success"><span class="icon"><span>Return to EdShed</span></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { request } from '@/edshed-common/api'
import config from '../config'
import utils from '../utils'

export default {
  name: 'Login',
  data (router) {
    return {
      section: 'Login',
      loading: '',
      username: '',
      password: '',
      response: ''
    }
  },
  computed: {
    edshedUrl () {
      return config.authURI
    },

    loginUrl () {
      const base = config.authURI
      const ret = window.location.origin

      return base + 'accesstoken?return_url=' + encodeURIComponent(ret)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$route.query.access_token) {
        const accessToken = this.$route.query.access_token
        this.exchangeAccessToken(accessToken)
      } else if (this.$store.state.user != null) {
        this.$router.push({ path: 'add-school', query: this.$route.query })
      }
    })
  },
  methods: {
    exchangeAccessToken (accessToken) {
      request('GET', 'accesstoken?access_token=' + accessToken, null)
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */
          if (data.error) {
            alert(data.error)
            return
          }

          if (data.sessionToken) {
            this.$store.commit('SET_TOKEN', data.sessionToken)
          }

          this.$router.push({ name: 'LoadUser' })

          /* Setting user in the state and caching record to the localStorage */
          // if (data.user) {
          //   var token = data.user.token
          //   this.$store.commit('SET_USER', data.user)
          //   this.$store.commit('SET_TOKEN', token)

          //   console.log('token: ' + token)
          //   console.log('user: ' + JSON.stringify(data.user))

          //   if (window.localStorage) {
          //     window.localStorage.setItem('user', JSON.stringify(data.user))
          //     window.localStorage.setItem('token', token)
          //   }

          //   this.$router.push(data.redirect ? data.redirect : '/')
          // }
        })
        .catch((error) => {
          this.$store.commit('TOGGLE_LOADING')
          console.log(error)

          this.response = 'Password incorrect or user not found'
          this.toggleLoading()
        })
    },
    checkCreds (e) {
      const { username, password } = this
      console.log(e)
      console.log(username)
      if (username.trim() === '') {
        this.response = 'Username / email required'
        return
      } else if (!utils.isAlphaNumericPunctuated(username.trim()) && !utils.isEmail(username.trim())) {
        this.response = 'Username can only contain letters and numbers and - or _ or be a valid email address.'
        return
      } else if (password.trim() === '') {
        this.response = 'Password required'
        return
      }

      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')

      /* Making API call to authenticate a user */
      request('POST', 'auth', { username, password })
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */
          if (data.error) {
            const errorName = data.error.name
            if (errorName) {
              this.response = errorName === 'InvalidCredentialsError'
                ? 'Username / Password incorrect. Please try again.'
                : errorName
            } else {
              this.response = data.error
            }

            return
          }

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            console.log('token: ' + token)
            console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$router.push(data.redirect ? data.redirect : '/')
          }
        })
        .catch((error) => {
          this.$store.commit('TOGGLE_LOADING')
          console.log(error)

          this.response = 'Password incorrect or user not found'
          this.toggleLoading()
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>

<style scoped>

  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }

  .menuButtons {
    flex-direction: column;
    justify-content: center;
  }

  .menuButtons a {
    font-size: 1.6em;;
    width: 640px;
    max-width: 100%;
    height: 80px;
    margin: 20px auto;

  }
</style>
